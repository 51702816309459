import {
    Box,
    SimpleGrid,
    Stat,
    StatLabel,
    StatNumber,
    StatArrow,
    StatHelpText,
    Text,
    useColorModeValue
  } from '@chakra-ui/react'
  import { ReactNode } from 'react'
  
interface GenericStatsProps {
    title: String,
    stat: String,
    icon?: ReactNode,
    statDetails: { type: String, value: string }
}
  function GenericStats(props: GenericStatsProps) {
    const { title, stat, icon, statDetails } = props
    return (
        <Stat
        px={{ base: 4, md: 8 }}
        py={'5'}
        shadow={'xl'}
        border={'1px solid'}
        borderColor={useColorModeValue('gray.800', 'gray.500')}
        rounded={'lg'}>
        <StatLabel  fontWeight={'medium'} >
          {title}
        </StatLabel>
        <StatNumber color='orange.500' fontSize={'2xl'} fontWeight={'medium'}>
          {stat}
        </StatNumber>
        <StatHelpText>
            <StatArrow type={statDetails.type}/>
               { statDetails.value }
        </StatHelpText>
        {icon}
      </Stat>  
    )
  }

  export default GenericStats