import * as React from "react"

import {
    Box,
    Button,
    Code,
    Container,
    Flex,
    Grid,
    Heading,
    Image,
    Link,
    Stack,
    Text,
    VStack,
    theme,
    SimpleGrid,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    CheckboxIcon
  } from "@chakra-ui/react"

  import GenericStats from "../components/stats/genericstats"
  import SimpleHeading from "../components/misc/heading"
  import WithSpeechBubbles from "../components/misc/testimonials"
import { BsPerson } from 'react-icons/bs'
import { PhoneIcon, QuestionIcon } from "@chakra-ui/icons"
import GetEmailAddress from "../components/misc/mailbox"

 export default function Home() {
    return (
      <Container maxW={'5xl'}>
        <Stack
          textAlign={'center'}
          align={'center'}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}>
            <Image scale={'200'} src="logo_test1.png"></Image>
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight={'110%'}>
          </Heading>
          <Text fontSize='xl' color={'gray.500'} maxW={'3xl'}>
            AI Intelligence & Automation Around Your Home
          </Text>
        <GetEmailAddress/>
          <InputGroup>
          <InputLeftElement>
          <QuestionIcon w={5} h={5}/>
          </InputLeftElement>
          <Input variant='filled' size='lg' type='address' placeholder='Property Address'></Input>
          <InputRightElement>
          <QuestionIcon color='green.500'/>
          </InputRightElement>
          </InputGroup>
          <Stack spacing={6} direction={'row'}>
            <Button
              rounded={'full'}
              px={6}
              colorScheme={'orange'}
              bg={'orange.400'}
              _hover={{ bg: 'orange.500' }}>
              Learn More
            </Button>
          </Stack>
          <SimpleGrid columns={3} spacing={10}>
           <GenericStats title={'New Accounts'} stat={'10,501'}  statDetails={{type: 'increase', value: '8%'}}/>
           <GenericStats title={'Minutes Saved'} stat={'1,000,000'}  statDetails={{type: 'increase', value: '2500%'}}/>
           <GenericStats title={'Properties Managed'} stat={'21,221'}  statDetails={{type: 'increase', value: '25%'}}/>
          </SimpleGrid>
          <WithSpeechBubbles/>
        </Stack>
      </Container>
    )
  }