import { ColorModeScript } from "@chakra-ui/react"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { App } from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { AmplifyProvider, Authenticator} from '@aws-amplify/ui-react'
const { NODE_ENV } = process.env;
if ( NODE_ENV !== "development") {
  awsExports.oauth.redirectSignIn = 'https://mydomo.ai'
  awsExports.oauth.redirectSignOut = 'https://mydomo.ai'
}
Amplify.configure(awsExports);
console.log("ENV=" + process.env)
console.log("NODEENV=" + NODE_ENV)
console.log(awsExports)
const container = document.getElementById("root")
if (!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

root.render(
  <React.StrictMode>
    <Authenticator.Provider>
    <ColorModeScript />
    <App />
    </Authenticator.Provider>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

