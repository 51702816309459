"use client";
import { Auth } from "@aws-amplify/ui-react";
import {
  withAuthenticator,
  Authenticator,
  View,
  useAuthenticator,
  user,
} from "@aws-amplify/ui-react";
import {
  useToast,
  Image,
  Box,
  Flex,
  Avatar,
  Badge,
  Text,
  Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { FcGoogle } from "react-icons/fc";

interface Props {
  children: React.ReactNode;
}


const Login = (props: Props) => {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const { children } = props;
  const loginUrl =
    "https://domof17d27b8-f17d27b8-dev.auth.us-west-2.amazoncognito.com/login?response_type=code&client_id=5uhd5eiata3jimiq5j3c8q976g&redirect_uri=https://mydomo.ai";
  const name = user?.attributes.name;
  const picture = user?.attributes.picture;
  console.log(name);
  if (user?.username) {
    return (
      <Menu>
        <MenuButton
          as={Button}
          rounded={"full"}
          variant={"link"}
          cursor={"pointer"}
          minW={0}
        >
          <Avatar size={'sm'} name={name} src={picture} />
        </MenuButton>
        <MenuList alignItems={'center'}>
          <br/>
          <Center>
            <Avatar size={'2xl'} src={picture} name={name}/>
          </Center>
          <br/>
          <Center>
            <p>{name}</p>
          </Center>
          <br/>
          <MenuDivider/>
          <MenuItem ><Button onClick={signOut}>Logout</Button></MenuItem>
        </MenuList>
      </Menu>
    );
  } else {
    return (
      <Button leftIcon={<FcGoogle />}>
        <Link href={loginUrl}>Sign in wth Google or Create Account</Link>
      </Button>
    );
  }
};
const NavLink = (props: Props) => {
  const { children } = props;

  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        bg: useColorModeValue("orange.200", "orange.700"),
      }}
      href={"#"}
    >
      {children}
    </Box>
  );
};
export default function NavBar() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, signOut } = useAuthenticator((context) => [context.user]);

  return (
    <>
      <Box
        boxShadow="lg"
        bg={useColorModeValue("orange.300", "orange.700")}
        px={4}
      >
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <Box>
            <Link href="/">
              <Image src="logo_32.png"></Image>
            </Link>
          </Box>
          <Flex alignItems={"center"}>
            <Stack direction={"row"} spacing={7}>
              <Button onClick={toggleColorMode}>
                {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
              </Button>
              <Menu>
                <Login />
              </Menu>
            </Stack>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
