import {
    Box,
    Button,
    SimpleGrid,
    Stack,
    Input,
    Flex,
    Icon,
    Text,
    useColorModeValue
  } from '@chakra-ui/react'
  import { ReactNode } from 'react'
  import { ReactElement } from 'react'
  import { FcIdea } from 'react-icons/fc'

  interface FeatureProps {
    title: string,
    text: string,
    icon?: ReactElement
  }

function generateEmail() {
    return (
        "test"
    )
}
  const Feature = (props: FeatureProps) => {
    const { title, text, icon } = props
    return (
        <Stack>
        <Button onClick={generateEmail}>Get Email Address</Button>
        </Stack>
    )

  }

  export default function GetEmailAddress() {
    return (
        <Input></Input>
        //<Button onClick={generateEmail}>Get Email Address</Button>
    )
  }